import { AE_OPERATION_TYPE_NAMES, CURRENCY_SHORT_CODES, FIAT_CURRENCY_NAMES } from '@/constants/auto-exchange'
import moment from 'moment-mini'
import Decimal from 'decimal.js'

export default function (data) {
  const {
    operation_id,
    session_id,
    timestamp,
    type,
    first_amount,
    second_amount,
    first_currency,
    second_currency,
    exchange_rate,
    offer_operation_id,
    banknotes,
    city,
    country,
    min_match_amount,
    status,
    additional_info,
    user_account_info,
    payment_types,
    operation_type,
    payment_reason,
    response_offer_op_id,
  } = data || {}
  const result = {
    banknotes,
    city,
    country,
    exchange_rate,
    min_match_amount,
    operation_id,
    first_amount,
    first_currency,
    second_amount,
    second_currency,
    session_id,
    type,
    offer_operation_id,
    additional_info,
    status,
    timestamp,
    user_account_info,
    payment_types,
    operation_type,
    payment_reason,
    response_offer_op_id,
  }
  result.minMatchAmountDisplay = min_match_amount
  result.isBill = result.operation_type === AE_OPERATION_TYPE_NAMES.AUTOEXCHANGE_BILL
  result.firstCurrencyShort = CURRENCY_SHORT_CODES[result.first_currency]
  result.secondCurrencyShort = CURRENCY_SHORT_CODES[result.second_currency]
  result.user_id = result.user_account_info?.user_id
  result._time = moment.unix(timestamp).format('DD/MM/YY HH:mm')
  result._rateCurrency = `${result.firstCurrencyShort}/${result.secondCurrencyShort}`
  result._offer = `${result.first_amount !== '0' ? result.first_amount : ''} ${result.firstCurrencyShort}`
  result._price = result.exchange_rate
  if (result.isBill) {
    if (result.second_currency === FIAT_CURRENCY_NAMES.RUB) result.minMatchAmountDisplay = new Decimal(result.first_amount).mul(result.exchange_rate).toFixed()
  }
  return result
}
